.ip-list-container {
    width: 100%;
    max-width: 1000px;
    margin-top: 10px;
}

.ip-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ip-list-index {
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
}

.ipList-field {
    font-family: "Roboto", sans-serif;
    padding: 10px;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 2.5px;
    background-color: #222;
    flex: 1;
    font-size: 18px;
    margin-right: 5px;
    min-width: 75px;
    border-right: 2px solid #c40711;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.ipList-field.server-name {
    font-weight: bold;
}

.remove-button {
    padding: 12px 8px;
    font-size: 18px;
    background-color: #781000;
    border-radius: 2.5px;
    border: none;
    color: white;
    cursor: pointer;
    white-space: nowrap;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.remove-button:hover {
    filter: brightness(70%);
    transition: filter 0.3s ease;
}

@media (max-width: 768px) {
    .ip-list-index {
        font-size: 16px;
    }
    .ipList-field {
        font-size: 14px;
    }
    .remove-button {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .ip-list-index {
        visibility: hidden;
        font-size: 0px;
        margin: 0;
        padding: 0;
    }
}