.ProductIpPage {
    height: 100%;
    min-height: 100vh;
    background: #1c1c1c;
    background-size: cover;
    background-position: center;
    color: white;

    display: flex;
    flex-direction: column;
}

.ip-block {
    display: flex;
    flex-direction: column;
    background-color: #181818;
    border-radius: 5px;
    border-bottom: 2px solid #c40711;
    width: 80%;
    max-width: 1200px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-items: center;
}