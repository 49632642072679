.profile-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    font-family: "Roboto", sans-serif;
}

.profile-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f0f0f;
    padding: 20px;
    padding-top: 5px;
    color: white;
}

.profile-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.profile-block {
    background-color: #181818;
    border-radius: 5px;
    border-bottom: 2px solid #c40711;
    width: 80%;
    max-width: 1200px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-info {
    display: flex;
    align-items: center;
    align-content: center;
}

.profile-avatar {
    width: 125px;
    border-radius: 5px;
    margin-right: 20px;
    background-color: #0f0f0f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.profile-avatar:hover {
    filter: brightness(75%);
    transition: filter 0.3s ease;
}

.profile-details {
    font-size: 14px;
    color: #a0a0a0;
    font-weight: 450;
}

.profile-username {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff;
}

.steamIdText {
    margin-bottom: -5px;
}

.profile-divider {
    width: 100%;
    height: 2px;
    background-color: #c40711;
    margin: 20px 0;
}

@media screen and (max-width: 768px) {
    .profile-avatar {
        width: 100px;
    }
}

@media screen and (max-width: 400px) {
    .profile-avatar {
        width: 75px;
    }
}

@media screen and (max-width: 360px) {
    .profile-avatar {
        width: 60px;
    }
}