.auth-container {
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.auth-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
}

.logo-container,
.auth-content,
.auth-footer {
    z-index: 2;
}

.main-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
    z-index: 2;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 100%;
    height: auto;
    text-align: center;
}

.logo img {
    width: 60%;
    padding: 45px;
    border-bottom: 2px #c40711 solid;
}

.auth-content {
    text-align: center;
    color: white;
    margin: 0px 10px;
    margin-top: 0;
}

.auth-content h1 {
    font-size: 36px;
    margin-bottom: -10px;
    color: #ffffff;
}

.auth-content h1 span {
    color: #c40711;
}

.auth-content p {
    font-size: 18px;
    margin-bottom: 5px;
}

.steam-button:hover {
    background-color: #a3060e;
}

.auth-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 10px;
    color: #606060;
    font-family: "Roboto", sans-serif;
    z-index: 3;
}

.auth-footer p {
    margin: 0;
}

.auth-footer .footer-left {
    float: left;
    margin-left: 10px;
}

.auth-footer .footer-right {
    float: right;
    margin-right: 10px;
}

@media screen and (max-width: 1280px) {
    .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .logo img {
        width: 60%;
        padding: 45px;
        border-bottom: 2px #c40711 solid;
    }

    .auth-footer {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 11px;
    }

    .auth-footer .footer-left {
        margin: 0;
    }

    .auth-footer .footer-right {
        margin: 0;
        margin-top: 10px;
    }
}