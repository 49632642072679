.header {
    background-color: #181818;
    border-bottom: 2px solid #c40711;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    z-index: 10;
    position: relative;

    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    height: 50px;
    align-content: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-container {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-logo {
    height: 30px;
}

.header-logo:hover {
    filter: brightness(0) saturate(100%) invert(11%) sepia(76%) saturate(5920%) hue-rotate(351deg) brightness(94%) contrast(104%);
}

.header-right {
    display: flex;
    align-items: center;
}

.header-button {
    margin-left: 1rem;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}

.header-button:hover {
    color: #c40711;
    transition: color 0.3s ease;
}

.menu-icon {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    cursor: pointer;
}

.menu-bar {
    width: 30px;
    height: 3px;
    background-color: #fff;
}

@media (max-width: 768px) {
    .nav {
        position: fixed;
        top: 0;
        right: 0;
        flex-direction: column;
        background-color: rgba(15, 15, 15, 0.85);
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        display: none;
        z-index: 4;
    }

    .nav.open {
        display: flex;
    }

    .nav a, .header-button.logout {
        margin: 15px 0;
        padding: 10px 20px;
        width: 80%;
        text-align: center;
        font-size: 32px;
        font-weight: 1000;
    }

    .menu-icon {
        display: flex;
        right: 20px;
        top: 15px;
        z-index: 10;
    }
}