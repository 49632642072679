html {
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: "Roboto", sans-serif;
}

@-ms-viewport {
  width: device-width; }