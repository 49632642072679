.ip-form-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
}

.ip-form-info {
    font-family: "Roboto", sans-serif;
    color: #cbcbcb;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
}

.ip-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #181818;
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
}

.input-field {
    font-family: "Roboto", sans-serif;
    padding: 10px;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 2.5px;
    background-color: #222;
    flex: 1;
    font-size: 18px;
    margin-right: 5px;
    min-width: 75px;
    border-right: 2px solid #c40711;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.add-button {
    padding: 10px 10px;
    font-size: 18px;
    background-color: #008000;
    border-radius: 2.5px;
    border: none;
    color: white;
    cursor: pointer;
    white-space: nowrap;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.add-button:hover {
    filter: brightness(70%);
    transition: filter 0.3s ease;
}

@media (max-width: 768px) {
    .input-field {
        font-size: 14px;
    }
    .add-button {
        font-size: 14px;
    }
}