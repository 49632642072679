.InformationText {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    padding: 25px 0px;
}

.products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin: 0 auto;

    padding-bottom: 5px;
}

.Product {
    font-family: "Roboto", sans-serif;
    font-size: 17px;

    flex: 0 1 250px;

    background: #222;
    padding: 8px;
    border-bottom: 2px solid #fff;
    border-radius: 5px;

    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
}

.ProductImage {
    width: 100%;
    border-radius: 2.5px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
}

.ProductImage:hover {
    cursor: pointer;
    filter: brightness(70%);
    transition: filter 0.3s ease;
}

.ProductText {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
}

.ProductButtonsGroup {
    display: flex;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 2.5px;
    justify-content: center;
    font-size: 16px;
}

.ProductDownloadButton {
    border-radius: 2.5px;
    background: #0f4d0f;
    padding: 10px 12px;
    margin-right: 5px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
}

.ProductDownloadButton:hover {
    cursor: pointer;
    filter: brightness(70%);
    transition: filter 0.3s ease;
}

.ProductIPButton {
    border-radius: 2.5px;
    padding: 10px 12px;
    margin-left: 5px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
}

.ProductIPButton:hover {
    cursor: pointer;
    filter: brightness(70%);
    transition: filter 0.3s ease;
}

.ProductButtonText {
    color: #fff;
    font-weight: 500;
}

.ProductButtonIcon {
    color: #fff;
}

a:link, a:visited {
    text-decoration: none;
}

@media screen and (max-width: 360px) {
    .ProductButtonsGroup {
        font-size: 15px;
    }
}

@media screen and (max-width: 345px) {
    .ProductButtonsGroup {
        font-size: 14px;
    }
}