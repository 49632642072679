.footer {
    background-color: #181818;
    color: #a0a0a0;
    padding: 15px 25px;
    font-family: "Roboto", sans-serif;
    border-top: 2px solid #c40711;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}

.footer-content p {
    margin: 0;
}

.social-icons {
    display: flex;
}

.footerInfo {
    font-size: 14px;
}

.social-icons a {
    color: #a0a0a0;
    margin-left: 15px;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #c40711;
}

.legal-info {
    margin-top: 5px;
    font-size: 12px;
    text-align: left;
}

.legal-info p {
    margin: 0;
    line-height: 1.5;
    color: #a0a0a0;
}

@media screen and (max-width: 1280px) {
    .legal-info {
        margin-top: 15px;
        font-size: 12px;
        text-align: center;
    }
}