.discordButton {
    background-color: #c40711;
    width: 200px;
    height: 25px;
    line-height: 25px;
    margin: auto;
    color: #fff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: transparent;
}

.discordButton span {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    width: 75%;
    font-size:10px;
    text-transform: uppercase;
    left: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.discordButton span,
.discordButton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
}

.discordButton .icon .fa {
    font-size: 30px;
    line-height: 50px;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.discordButton .icon {
    width: 25%;
    right: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.discordButton span,
.discordButton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.discordButton span:after {
    content: '';
    background-color: white;
    width: 2px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: -1px;
}

.discordButton.success span,
.discordButton:hover span {
    left: -72%;
    opacity: 0;
}

.discordButton.success .icon,
.discordButton:hover .icon {
    width: 100%;
}

.discordButton.success .icon .fa,
.discordButton:hover .icon .fa {
font-size: 45px;
}

@media (max-width: 450px) {
    .discordButton {
        width: 175px;
    }

    .discordButton span {
        font-size:9px;
    }
}

@media (max-width: 380px) {
    .discordButton {
        width: 160px;
    }

    .discordButton span {
        font-size:9px;
    }
}